.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid blue; */
  padding-bottom: 2rem;
}

.swiper-button-prev,
.swiper-button-next {
  color: white;
  font-size: 5px;
}

/* Add this CSS to your stylesheets */
/* Change the color of active dot in pagination */
.swiper-pagination-bullet-active {
  background-color: #d6ff41; /* Change this to the desired color */
  border: "2px solid red";
}

/* Change the color of inactive dots in pagination */
.swiper-pagination-bullet {
  background-color: #d6ff4d; /* Change this to the desired color */
  /* margin-top: 100px; */
  border: "2px solid red";
}

/* Change the color of Swiper navigation arrows */
.swiper-button-next,
.swiper-button-prev {
  color: #d6ff41; /* Change this to the desired color */
}

.mySwiper{
  /* border: 2px solid red; */
}
